.component-alert-background(v-if="parameters.show")
  .component-alert(:class="{raise: parameters.grow, smaller: !parameters.grow}")
    .alert-container.shadow-lg.rounded-lg.overflow-hidden
      header.pt-6.px-6.text-left.text-dark.bg-white
        .flex.self-center.items-center(class="-mx-2")
          .flex-initial.w-10.px-2
            i.material-icons.text-danger(class="!text-3xl") error
          .flex-auto.text-xl.px-2
            | {{ parameters.title }}
      .content.py-4.px-6.bg-white
        .flex.self-center
          .flex-initial.w-10
          .flex-auto.text-medium.text-left(v-html="parameters.message")
      footer.bg-white.overflow-hidden.text-right.px-5.py-5
        button.bg-transparent.text-gray-500.w-28.h-12.inline-block.rounded-md.hover__bg-white(class="w-120px" v-if="parameters.confirmButton" @click="fn.cancel()") 취소
        button.bg-danger.text-white.h-12.inline-block.rounded-md.mx-3.shadow-lg(class="w-120px shadow-danger/50" @click="fn.confirm()") 확인