import { createRouter, createWebHistory } from 'vue-router'
import { routes } from '@/routes'
import '@vendor/support-js/override'
import 'material-icons/iconfont/material-icons.css'
import '@css/application'
import { rootMount, rootUse, usePinia } from '@/components/root'
import $ from 'jquery'
// @ts-ignore
window.jQuery = window.$ = $

document.addEventListener('DOMContentLoaded', () => {
    const router = createRouter({
        history: createWebHistory(),
        routes
    })
    usePinia()
    rootUse(router)
    rootMount('#goodpin')
})