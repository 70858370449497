import _ from 'lodash'

export const blank = (value: any): boolean => {
    return (typeof value !== "boolean") && (typeof value !== "number") && (typeof value !== "function") && _.isEmpty(value)
}

export const present = (value: any): boolean => {
    return !blank(value)
}

export const presence = (value: any): any | boolean => {
    return present(value) ? value : false
}