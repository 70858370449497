import { reactive } from 'vue'
import '@vendor/components/alert/style'
import { presence } from '@vendor/support-js/alias'

export const Alert = {
    template: require('@vendor/components/alert/template'),
    setup() {
        const parameters = reactive({
            show:          false,
            title:         '',
            message:       '',
            confirmButton: false,
            resolve:       undefined,
            reject:        undefined,
            grow:          false
        })
        const fn         = {
            show:    (message: string, title: string = undefined, isConfirm: boolean = false) => {
                return new Promise((resolve, reject) => {
                    parameters.title         = presence(title) || (isConfirm ? '승인' : '알림')
                    parameters.message       = String(message).replace(/\n/gi, '<br/>')
                    parameters.grow          = true
                    parameters.show          = true
                    parameters.resolve       = resolve
                    parameters.reject        = reject
                    parameters.confirmButton = isConfirm
                })
            },
            confirm: () => {
                parameters.grow = false
                setTimeout(() => {
                    parameters.show = false
                    parameters.resolve()
                }, 100)
            },
            cancel:  () => {
                parameters.grow = false
                setTimeout(() => {
                    parameters.show = false
                    parameters.reject()
                }, 100)
            }
        }
        return { parameters, fn }
    }
}