import './style'
import { reactive } from 'vue'

export const Progress = {
    template: require('@vendor/components/progress/template'),
    setup() {
        const data    = reactive({
            isShow: false
        })
        const methods = {
            show: (message: string = '', timeout = 30) => {
                data.isShow = true
                setTimeout(() => {
                    methods.hide()
                }, timeout * 1000)
            },
            hide: () => {
                data.isShow = false
            }
        }
        return { data, methods }
    }
}