import { createApp, ref } from 'vue'
import { Alert } from '@vendor/components/alert'
import { Progress } from '@vendor/components/progress'
import { createPinia } from 'pinia'

const Root = createApp({
    components: {
        Alert,
        Progress
    },
    template:   require('@views/layouts/app'),
    setup() {
        const alert = ref(null)
        return { alert }
    }
})

export const rootUse = (useObject) => {
    Root.use(useObject)
}

export const rootMount = (id) => {
    Root.mount(id)
    Root.provide('Ssomee', 'vue3')
}

export const usePinia = () => {
    Root.use(createPinia())
}

export { Root }