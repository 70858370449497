export const routes = [
    {
        path:      '/',
        name:      'Main',
        component: async () => import('@/components/main')
    },
    {
        path:      '/error',
        name:      'Error',
        component: async () => import('@/components/error')
    },
    {
        path:      '/p/:id',
        name:      'Payment',
        component: async () => import('@/components/payment')
    },
    {
        path:      '/success/:id',
        name:      'Success',
        component: async () => import('@/components/success')
    },
    {
        path:      '/close',
        name:      'Close',
        component: async () => import('@/components/close')
    }
]